import '../styles/contact.scss'

import { format } from 'date-fns'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import ImageHero from '../components/ImageHero'
import LoadingSpinner from '../components/LoadingSpinner'
import SEO from '../components/SEO'
import Layout from '../layouts/Layout'

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsNetworkSite {
        nodes {
          email
          siteName
          position
        }
      }

      datoCmsContactPage {
        heroHeadline
        heroBody
        heroBodyNode {
          childMarkdownRemark {
            html
          }
        }
        phoneNumber
        phoneExtension
        divisions {
          name
          email
        }
      }

      contactHero: file(relativePath: { eq: "contact_hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactHeroTablet: file(
        relativePath: { eq: "contact_hero_tablet.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const dato = data.datoCmsContactPage
  const getHtml = node => ({
    __html: node.childMarkdownRemark.html,
  })

  // These have the same aspect ratio so we don't encounter the gatsby-image sizing bug
  const contactHero = [
    {
      ...data.contactHero.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
    {
      ...data.contactHeroTablet.childImageSharp.fluid,
      media: `(max-width: 1024px)`,
    },
  ]

  const formData = useRef({
    name: null,
    'company-name': null,
    phone: null,
    email: null,
    'other-subject': null,
    'product-name': null,
    'international-distribution': null,
    'target-cost-per-unit': null,
    'other-product-form': null,
    'other-product-category': null,
    'estimated-order-quantity': null,
    'estimated-annual-volume': null,
    'estimated-launch': null,
    'privacy-consent': false,
    message: null,
  })

  const [formSelectData, setFormSelectData] = useState({
    'preferred-contact-method': null,
    'primary-distribution-channel': null,
    'inquiry-subject': null,
    'planned-distribution': null,
    'product-category': null,
    'product-form': null,
  })

  const contactMethods = ['Phone', 'Email']

  const primaryDistributionChannels = [
    'Retail',
    'E-Commerce',
    'DTC',
    'Other',
  ]

  const inquirySubjects = [
    'Product Development / Request Quote',
    'HR Contact',
    'Accounting Contact',
    'General Inquiry/Other',
  ]

  const distributionOptions = ['USA', 'International']

  const productCategories = [
    'Botanicals',
    'Dietary Supplements',
    'Household',
    'Minerals',
    'Nutrition Bars',
    'Over-the-counter',
    'Personal Care',
    'Pet Care',
    'Probiotics',
    'Sports Nutrition',
    'Vitamins',
    'Other',
  ]

  const productForms = [
    'Tablet',
    'Capsule',
    'Powder',
    'Liquid',
    'Gel',
    'Bar',
    'Other',
  ]

  const handleChange = e => {
    const key = e.target.name
    const value = e.target.value
    formData.current[key] = value
  }

  const handleChangeSelect = e => {
    const key = e.target.name
    const value = e.target.value
    setFormSelectData(prev => ({ ...prev, [key]: value }))
  }

  const [consent, setConsent] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    setSubmitting(true)

    const encode = data => {
      return Object.keys(data)
        .map(
          key =>
            encodeURIComponent(key) +
            '=' +
            encodeURIComponent(data[key])
        )
        .join('&')
    }
    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encode({
          'form-name': 'contact-form',
          ...formData.current,
          ...formSelectData,
          'privacy-consent': consent,
        }),
      })
      if (response) {
        setSubmitting(false)
      }
      if (response.ok) {
        setSubmitted(true)
      } else {
        alert(
          `Sorry, there was an error submitting this form:
${response.status} ${response.statusText}`
        )
      }
    } catch (error) {
      alert(`Sorry, there was an error submitting this form: 
${error}`)
    }
  }

  return (
    <Layout id="contact-page">
      <SEO title="Contact Us" description={dato.heroBody} />
      <ImageHero
        fluid={contactHero}
        alt="Spoons full of nutrition and wellness supplements"
      >
        <h1>{dato.heroHeadline}</h1>
        <div
          className="lede"
          dangerouslySetInnerHTML={getHtml(dato.heroBodyNode)}
        />
      </ImageHero>
      <section id="form">
        <section id="instructions">
          <h3>
            Call us at{' '}
            <a
              className="tel"
              href={
                'tel: ' +
                dato.phoneNumber.replace(/[\s+.()\-–—]+/g, '') +
                (dato.phoneExtension ? ',' + dato.phoneExtension : '')
              }
            >
              {dato.phoneNumber +
                (dato.phoneExtension
                  ? ' ext. ' + dato.phoneExtension
                  : '')}
            </a>
          </h3>
          <span className="or">{!submitted && <span>or</span>}</span>
          {submitted ? (
            <div>
              <h3>Your inquiry has been submitted.</h3>
              <p>
                Thank you for your interest. We will get back to you as
                quickly as possible.
              </p>
            </div>
          ) : (
            <h3>Send us a message using the following form:</h3>
          )}
        </section>
        {!submitted && !submitting && (
          <form
            id="contact-form"
            name="contact-form"
            data-netlify={true}
            method="post"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input
              type="hidden"
              name="form-name"
              value="contact-form"
              aria-hidden
            />
            <input type="hidden" name="bot-field" aria-hidden />
            <div>
              <label htmlFor="name">Your Name *</label>
              <input
                name="name"
                id="name"
                type="text"
                required
                onChange={handleChange}
              />
              <label htmlFor="company-name">Company Name *</label>
              <input
                name="company-name"
                id="company-name"
                type="text"
                required
                onChange={handleChange}
              />

              <label htmlFor="email">Email Address *</label>
              <input
                name="email"
                id="email"
                type="email"
                required
                onChange={handleChange}
              />

              <label htmlFor="phone">Phone Number *</label>
              <input
                name="phone"
                id="phone"
                type="phone"
                required
                onChange={handleChange}
              />

              <label htmlFor="preferred-contact-method">
                Preferred Contact Method *
              </label>
              <select
                name="preferred-contact-method"
                id="preferred-contact-method"
                required
                onChange={handleChangeSelect}
                defaultValue={''}
              >
                <option disabled value={''}>
                  Select
                </option>
                {contactMethods.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <label htmlFor="inquiry-subject">Inquiry Subject *</label>
              <select
                name="inquiry-subject"
                id="inquiry-subject"
                required
                onChange={handleChangeSelect}
                defaultValue={''}
              >
                <option disabled value={''}>
                  Select
                </option>
                {inquirySubjects.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {formSelectData['inquiry-subject'] ===
              'General Inquiry/Other' ? (
                <div>
                  <label htmlFor="other-subject">
                    Please specify inquiry subject *
                  </label>
                  <input
                    name="other-subject"
                    id="other-subject"
                    type="text"
                    required
                    onChange={handleChange}
                  />
                </div>
              ) : (
                <input type="hidden" name="other-subject" aria-hidden />
              )}
              {formSelectData['inquiry-subject'] ===
              'Product Development / Request Quote' ? (
                <div>
                  <label htmlFor="product-name">Product Name *</label>
                  <input
                    name="product-name"
                    id="product-name"
                    type="text"
                    required
                    onChange={handleChange}
                  />

                  <label htmlFor="primary-distribution-channel">
                    Primary Distribution Channel *
                  </label>
                  <select
                    name="primary-distribution-channel"
                    id="primary-distribution-channel"
                    required
                    onChange={handleChangeSelect}
                    defaultValue={''}
                  >
                    <option disabled value={''}>
                      Select
                    </option>
                    {primaryDistributionChannels.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <label htmlFor="planned-distribution">
                    Planned Distribution *
                  </label>
                  <select
                    name="planned-distribution"
                    id="planned-distribution"
                    required
                    onChange={handleChangeSelect}
                    defaultValue={''}
                  >
                    <option disabled value={''}>
                      Select
                    </option>
                    {distributionOptions.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {formSelectData['planned-distribution'] ===
                  'International' ? (
                    <div>
                      <label htmlFor="international-distribution">
                        Please specify which country *
                      </label>
                      <input
                        name="international-distribution"
                        id="international-distribution"
                        type="text"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    <input
                      type="hidden"
                      name="international-distribution"
                      aria-hidden
                    />
                  )}

                  <label htmlFor="product-form">Product Form *</label>
                  <select
                    name="product-form"
                    id="product-form"
                    required
                    onChange={handleChangeSelect}
                    defaultValue={''}
                  >
                    <option disabled value={''}>
                      Select
                    </option>
                    {productForms.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  {formSelectData['product-form'] === 'Other' ? (
                    <div>
                      <label htmlFor="other-product-form">
                        Please specify product form *
                      </label>
                      <input
                        name="other-product-form"
                        id="other-product-form"
                        type="text"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    <input
                      type="hidden"
                      name="other-product-form"
                      aria-hidden
                    />
                  )}

                  <label htmlFor="product-category">
                    Product Category *
                  </label>
                  <select
                    name="product-category"
                    id="product-category"
                    required
                    onChange={handleChangeSelect}
                    defaultValue={''}
                  >
                    <option disabled value={''}>
                      Select
                    </option>
                    {productCategories.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  {formSelectData['product-category'] === 'Other' ? (
                    <div>
                      <label htmlFor="other-product-category">
                        Please specify product category *
                      </label>
                      <input
                        name="other-product-category"
                        id="other-product-category"
                        type="text"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    <input
                      type="hidden"
                      name="other-product-category"
                      aria-hidden
                    />
                  )}

                  <label htmlFor="target-cost-per-unit">
                    Target Cost Per Unit *
                  </label>
                  <input
                    name="target-cost-per-unit"
                    id="target-cost-per-unit"
                    type="text"
                    onChange={handleChange}
                    required
                  />

                  <label htmlFor="estimated-order-quantity">
                    Estimated Order Quantity *
                  </label>
                  <input
                    name="estimated-order-quantity"
                    id="estimated-order-quantity"
                    type="text"
                    inputmode="numeric"
                    pattern="[\d\,]*"
                    onChange={handleChange}
                    required
                  />

                  <label htmlFor="estimated-annual-volume">
                    Estimated Annual Volume *
                  </label>
                  <input
                    name="estimated-annual-volume"
                    id="estimated-annual-volume"
                    type="text"
                    inputmode="numeric"
                    pattern="[\d\,]*"
                    onChange={handleChange}
                    required
                  />

                  <label htmlFor="estimated-launch">
                    Estimated Launch Date *
                  </label>
                  <input
                    name="estimated-launch"
                    id="estimated-launch"
                    type="date"
                    min={format(new Date(), 'yyyy-MM-dd')}
                    onChange={handleChange}
                    required
                  />
                </div>
              ) : (
                <div>
                  <input
                    type="hidden"
                    name="product-name"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="primary-distribution-channel"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="planned-distribution"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="international-distribution"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="target-cost-per-unit"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="product-category"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="other-product-form"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="other-product-category"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="product-form"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="estimated-order-quantity"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="estimated-annual-volume"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="estimated-launch"
                    aria-hidden
                  />
                  <input
                    type="hidden"
                    name="privacy-consent"
                    aria-hidden
                  />
                </div>
              )}
            </div>

            <div>
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                aria-label="Message"
                onChange={handleChange}
              />
            </div>
            <div>
              <div className="consent-wrap">
                <button
                  className="consent"
                  onClick={() => setConsent(prev => !prev)}
                  aria-label="privacy consent"
                  tabIndex={0}
                  type="button"
                >
                  <input
                    type="checkbox"
                    id="privacy-consent"
                    required
                    checked={consent}
                    tabIndex={-1}
                    onChange={() => null}
                    aria-label="Privacy Consent"
                  />
                  <p>
                    I agree that INW may contact me in accordance with
                    their{' '}
                    <a href="/privacy" target="_blank">
                      Privacy Policy
                    </a>
                    .
                  </p>
                </button>
              </div>
              <input
                name="submit"
                type="submit"
                value="Send Message"
                className="button"
                aria-label="Send Message"
              />
            </div>
          </form>
        )}
        {submitting && <LoadingSpinner color="#fff" />}
      </section>
    </Layout>
  )
}

export default ContactPage

ContactPage.propTypes = {
  location: PropTypes.object,
}
