import './LoadingSpinner.scss'

import React from 'react'

const LoadingSpinner = ({
  color = 'black',
  speed = 1200,
  count = 12,
  ...props
}) => {
  return (
    <div
      className="spinner"
      style={{
        '--count': count,
        '--color': color,
        '--speed': speed + 'ms',
      }}
      {...props}
    >
      {[...new Array(count)].map((_, i) => {
        const radians = ((360 / count) * i * Math.PI) / 180
        return (
          <div
            key={i}
            style={{
              animationDelay: `${(i / count - 1) * speed}ms`,
              top: `${Math.sin(radians) * 50 + 50}%`,
              left: `${Math.cos(radians) * 50 + 50}%`,
            }}
          />
        )
      })}
    </div>
  )
}

export default LoadingSpinner
